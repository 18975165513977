import React, { useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import $ from "jquery";

import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";
import ReactPaginate from "react-paginate";
import * as checkBtnPermission from "../../helpers/checkBtnPermission";

import view_icon from "../../assets/img/view-icon.svg";
import edit_icon from "../../assets/img/edit-icon.svg";
import agent_icon from "../../assets/img/agent-icon.svg";

import AgentAssignModal from "../../components/leads/AgentAssignModel";
import Dropdown from "../dropdown";
import { FilterContext } from "../../FilterContext";
import axios from "axios";
import useToast from "../../hooks/useToast";

class LeadsList extends React.Component {
  constructor(props) {
    super(props);
    //const params = useParams();
    //console.log('params',params);
    let queryString = props.location.search;
    console.log("queryString", queryString);

    const queryParams = new URLSearchParams(queryString);
    console.log("filterLeads", queryString.indexOf("lead"));
    let filterStage = "";
    let filterLeads = "";

    if (queryString.indexOf("stage") > 0) {
      filterStage = queryParams.get("stage");
      console.log(filterStage); //pizza
    } else if (queryString.indexOf("lead") == 1) {
      filterLeads = queryParams.get("lead");
      console.log("filterLeads", filterLeads);
    }

    this.state = {
      leadList: [],
      totalLeads: 0,
      offset: 0,
      perPage: Appconfig.perPage,
      currentPage: 1,
      pageCount: 0,

      statusList: "",
      stagingList: "",
      sourceList: "",
      staleList: "",
      agentListL: "",
      productList: "",
      filterKeyword: "",
      filterStatus: "",
      filterSupplier: "",
      filterStage: filterStage,
      filterCity: "",
      filterState: "",
      filterSource: "",
      filterAgent: "",
      filterStartDate: "",
      filterLeads: filterLeads,
      filterEnddate: "",
      sortBy: "DESC",
      sortName: "id",

      isLoaded: false,
      is_loader: true,
      flashMessage: Appconfig.getFlashMessage(),
      apiError: "",
      modalIsOpen: false,
      modalId: 0,
      login_user_role_id: "",
      ...(props?.globalFilters ? props.globalFilters : {}),
    };
    this.handleClickCsvData = this.handleClickCsvData.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.hendelPerPageChange = this.hendelPerPageChange.bind(this);
    let self = this;
    setTimeout(function () {
      self.state.flashMessage = Appconfig.setFlashMessage("");
    }, 2000);

    this.receivedData();

    // Get state List

    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
        this.state["login_user_role_id"] = userInfo.roleId;
        if (userInfo.roleId === 12) {
          this.state["filterSupplier"] = userInfo.supplier_id;
        }
      }
    }
    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "lead/getLeadFilters", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState(
                {
                  staleList: result.stateList.rows,
                  statusList: result.statusList.rows,
                  stagingList: result.stageList.rows,
                  sourceList: result.sourceList.rows,
                  agentList: result.agentList.rows,
                  productList: result.productList.rows,
                },
                () => {
                  console.log("result state:", this.state.staleList);
                  console.log("tyoe of:", typeof this.state.staleList[0]);

                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 100);
                }
              );
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }
  onFileUpload = (e) => {
    const { notify } = this.props;
    var fileSize = e.target.files[0].size;
    fileSize = fileSize / 1000000; //file size in mbin mb

    console.log("fileSize", fileSize);
    if (fileSize.toFixed(1) > 50) {
      // fileError["doc_file"] = "File size must be less then equal 5MB ";
    }

    const formData = new FormData();
    formData.append("doc_file", e.target.files[0]);
    formData.append("doc_name", e.target.doc_name);

    var token = Appconfig.getSessionToken();
    let self = this;
    if (token) {
      self.setState({ uploading: true });

      axios({
        method: "post",
        url: Appconfig.api_base_url + "lead/uploadLeadFile",
        data: formData,
        contentType: false,
        cache: false,
        processData: false,
        headers: { Authorization: "Bearer " + token },
      })
        .then(function (response) {
          e.target = null;
          if (response.data.status == 0) {
            console.log("response", response.data);
            notify("error", response.data.message);
            self.setState({ uploading: false });

            let items = response.data.data.map((e) => e.join(",")).join("\n");
            var data = new Blob([items], { type: "text/csv" });
            var csvURL = window.URL.createObjectURL(data);
            var tempLink = document.createElement("a");
            tempLink.href = csvURL;
            var timestr = common_helper.getCurrentDateTimeStr();
            tempLink.setAttribute("download", "ErrorList" + timestr + ".csv");
            tempLink.click();
            self.receivedData();
            self.setState({ uploading: false });
          } else if (response.data.status === 1) {
            notify("success", response.data.message);
            self.receivedData();
            self.setState({ uploading: false });
          }

          self.setState({ uploading: false });
          //handle success
          console.log("response2", response.data);
        })
        .catch(function (response) {
          //handle error
          this.setState({ uploading: false });

          notify("error", "Something went wrong");
          console.log(response);
        });
    } else {
      window.location.href = Appconfig.site_url;
    }
  };
  // hendel Serch
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        currentPage: 1,
        offset: 0,
        is_loader: true,
        // filterStatus: e.target.elements?.filterStatus?.value,
        // filterSupplier: e.target.elements?.filterSupplier?.value,

        filterKeyword: e.target.elements?.filterKeyword?.value,
        // filterStage: e.target.elements?.filterStage?.value,
        // filterState: e.target.elements?.filterState?.value,
        // filterSource: e.target.elements?.filterSource?.value,
        // filterAgent: e.target.elements?.filterAgent?.value,
        // filterProduct: e.target.elements?.filterProduct?.value,
        filterStartDate: e.target.elements?.filterStartDate?.value,
        filterEndDate: e.target.elements?.filterEndDate?.value,
        // filterCampaign: e.target.elements?.filterCampaign?.value,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // hendel Serch
  handleClickReset = (e) => {
    e.preventDefault();
    //console.log('ele',e.target.filterState);
    //console.log('ele2',e.target.elements.filterSource);
    this.setState(
      {
        currentPage: 1,
        offset: 0,
        is_loader: true,
        filterStatus: "",
        filterSupplier: "",
        filterKeyword: "",
        filterStage: "",
        filterState: "",
        filterSource: "",
        filterAgent: "",
        filterProduct: "",
        filterStartDate: "",
        filterEndDate: "",
        filterLeads: "",
        filterCampaign: "",
      },
      () => {
        this.receivedData();
      }
    );
  };

  // pagination
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };
  hendelPerPageChange = (event) => {
    this.setState({ perPage: event.target.value, offset: 0 }, () => {
      this.receivedData();
    });
  };
  // get data
  receivedData() {
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }
    this.props.setGlobalFilters &&
      this.props.setGlobalFilters({
        offset: this.state.offset,
        perPage: this.state.perPage,
        filterStatus: this.state.filterStatus,
        filterSupplier: this.state.filterSupplier,
        filterKeyword: this.state.filterKeyword,
        filterState: this.state.filterState,
        filterSource: this.state.filterSource,
        filterAgent: this.state.filterAgent,
        filterProduct: this.state.filterProduct,
        filterStage: this.state.filterStage,
        filterStartDate: this.state.filterStartDate,
        filterEndDate: this.state.filterEndDate,
        sortName: this.state.sortName,
        sortBy: this.state.sortBy,
        filterLeads: this.state.filterLeads,
      });

    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "lead/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: this.state.offset,
          perPage: this.state.perPage,
          filterStatus: this.state.filterStatus,
          filterSupplier: this.state.filterSupplier,
          filterKeyword: this.state.filterKeyword,
          filterState: this.state.filterState,
          filterSource: this.state.filterSource,
          filterAgent: this.state.filterAgent,
          filterProduct: this.state.filterProduct,
          filterStage: this.state.filterStage,
          filterStartDate: this.state.filterStartDate,
          filterEndDate: this.state.filterEndDate,
          sortName: this.state.sortName,
          sortBy: this.state.sortBy,
          filterLeads: this.state.filterLeads,
          filterCampaign: this.state.filterCampaign,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                is_loader: false,
                leadList: result.data.rows,
                totalLeads: result.data.count,
                pageCount: Math.ceil(result.data.count / this.state.perPage),
              });

              console.log("result:", result.data.rows);
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleStatusChange(event) {
    const target = event.target;
    const userId = target.value;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log("checked", target);
    console.log("value", target.value);

    var updateUserList = [];
    var newStatus = this.state.userList.map((row, index) => {
      console.log(row.id + "===" + userId);
      if (row.id == parseInt(userId)) {
        if (row.status == 1) var newStatus = 0;
        else var newStatus = 1;
        row.status = newStatus;
        console.log("updaterow", row);
      }
      updateUserList.push(row);
    });

    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }

    if (token) {
      fetch(Appconfig.api_base_url + "user/updateStatus/" + userId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          //"Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status == 1) {
              this.setState({ userList: updateUserList });
            } else {
              this.receivedData();
              this.setState({ apiError: result.message });
              setTimeout(() => {
                console.log("");
                this.setState({ apiError: false });
              }, 3000);
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    }

    //console.log('updateUserList',updateUserList);
    //this.setState({userList:updateUserList});
  }

  handleClickCsvData(event) {
    var userInfo = localStorage.getItem("lmsuser");

    var token = "";
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      //userInfo.hasOwnProperty('token')
      token = userInfo.token;
    }

    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "lead/download_csv", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: 0,
          perPage: 9999,
          filterStatus: this.state.filterStatus,
          filterSupplier: this.state.filterSupplier,
          filterKeyword: this.state.filterKeyword,
          filterState: this.state.filterState,
          filterSource: this.state.filterSource,
          filterAgent: this.state.filterAgent,
          filterStage: this.state.filterStage,
          filterStartDate: this.state.filterStartDate,
          filterEndDate: this.state.filterEndDate,
          filterLeads: this.state.filterLeads,
          filterCampaign: this.state.filterCampaign,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 0) {
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 5000);
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 5000);
            } else if (result.data.count > 0) {
              var Items = result.data.rows;
              const csvString = [
                [
                  "Product Name",
                  "Sub Product Name",
                  "Customer Name",
                  "Customer Email",
                  "Country Code",
                  "Customer Mobile",
                  "Address",
                  "Landmark",
                  "Province",
                  "City",
                  "Pincode",
                  "Driving License",
                  "Employment Type",
                  "Monthaly Income",
                  "Experience",
                  "Previous Loan",
                  // "Lead comment",
                  // "Admin comment",
                  "Supplier Name",
                  "Campaign",
                  "Agent Name",
                  "Source of lead",
                  "Lead Stage",
                  "Status",
                  "Created Date",
                ],
                ...Items.map((item) => [
                  item.pg.product_name,
                  item.spg.product_name,
                  item.first_name,
                  item.email_id,
                  item.country_code,
                  item.contact_number,
                  item.address,
                  item.landmark,
                  item.state ? item.state.state_name : "",
                  item.city ? item.city.city_name : "",
                  item.pincode,
                  item.driving_license,
                  item.employment_type,
                  item.monthaly_income,
                  item.experience,
                  item.previous_loan,
                  // item.comment,
                  // item.admin_comment,
                  item.supplier?.supplier_name,
                  item.campaign?.campaign_name,
                  item.user?.full_name,
                  item.source_of_lead,
                  item.lead_process_stage != null
                    ? item.lead_process_stage.lead_process_stage_name
                    : "",
                  item.lead_process_status != null
                    ? item.lead_process_status.lead_process_status_name
                    : "",
                  common_helper.getDateTime(item.createdAt),
                ]),
              ]
                .map((e) => e.join(","))
                .join("\n");

              var data = new Blob([csvString], { type: "text/csv" });
              var csvURL = window.URL.createObjectURL(data);
              var tempLink = document.createElement("a");
              tempLink.href = csvURL;
              var timestr = common_helper.getCurrentDateTimeStr();
              tempLink.setAttribute("download", "leadlist" + timestr + ".csv");
              tempLink.click();
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    let self = this;

    $("#perpage").selectpicker();
    $(document).ready(function () {
      if (typeof self.state.cityList === "object") {
        $(".selectpicker").selectpicker();
      }

      var today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      $(".input-daterange").datepicker({
        autoclose: true,
        endDate: "0d",
        format: "dd-mm-yyyy",
        maxViewMode: 2,
        todayHighlight: true,
        startDate: this.state?.filterStartDate,
        endDate: this.state?.filterEndDate,
      });

      $(
        ".filter-trigger, .filter-search-btn .btn, .btn-search-close, .filter-backdrop"
      ).click(function () {
        $("body").toggleClass("is-search");
      });
    });

    $(document).on("click", ".col-sort", function () {
      var sortBy = $(this).attr("data-sortBy");
      var sortName = $(this).attr("data-sortName");

      $(this).parent().find("i").removeClass("fa-sort-asc");
      $(this).parent().find("i").removeClass("fa-sort-desc");
      $(this).parent().find("i").addClass("fa-sort");

      if (sortBy == "DESC") {
        sortBy = "ASC";
        $(this).attr("data-sortBy", "ASC");
        $(this).find("i").removeClass("fa-sort");
        $(this).find("i").addClass("fa-sort-asc");
      } else {
        sortBy = "DESC";
        $(this).attr("data-sortBy", "DESC");
        $(this).find("i").removeClass("fa-sort");
        $(this).find("i").addClass("fa-sort-desc");
      }

      self.setState({ sortName: sortName, sortBy: sortBy }, () => {
        self.receivedData();
      });
    });
  }

  openModal(modalId) {
    this.setState({
      modalId,
      modalIsOpen: true,
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  handleDropdownChange = (value, field) => {
    this.setState({ [field]: value });
  };
  render() {
    const stylesObj = {
      backgroundColor: "#90ee90",
    };

    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <form onSubmit={this.handleSubmit}>
            <header className="head-title mb-3 d-flex align-items-center">
              <a href="/leads" className="btn-back">
                <i className="fa fa-caret-left"></i>
              </a>
              <h1 className="h3 mb-0">All Leads</h1>
              {/*<button type="button" className="btn btn-secondary btn-head-filter d-xl-inline-block d-none ml-auto collapsed" data-toggle="collapse" data-target="#CollapseFilter">Filter Search <i class=" fa fa-plus ml-2"></i></button>*/}
              <button
                type="button"
                className="btn btn-secondary ml-auto filter-trigger"
              >
                Filter Search <i class=" fa fa-search ml-2"></i>
              </button>
            </header>
            {/* <div className="card card-filter lead-filter mb-xl-4 collapse" id="CollapseFilter"> */}
            <div className="card card-filter lead-filter">
              <header
                className="card-header d-xl-none align-items-center d-none pt-2 pb-2 bg-white collapsed"
                data-toggle="collapse"
                data-target="#CollapseFilter"
              >
                <h4 className="m-0">Filter</h4>
              </header>
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="filter-item mb-2">
                      <label className="text-muted" for="FilterLeads">
                        Search Lead
                      </label>
                      <input
                        type="text"
                        name="filterKeyword"
                        className="form-control"
                        Placeholder="Search By Email/Mobile"
                        value={this.state.filterKeyword}
                        onChange={(e) => {
                          this.setState({ filterKeyword: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <Dropdown
                    key={"source_of_lead"}
                    field={"filterSource"}
                    title={"Select Source"}
                    data={this.state.sourceList}
                    labelField={"source_of_lead"}
                    value={this.state.filterSource}
                    valueField={"source_of_lead"}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />
                  <Dropdown
                    value={this.state.filterStatus}
                    field={"filterStatus"}
                    key={"lead_process_status_name"}
                    title={"Select Status"}
                    data={this.state.statusList}
                    labelField={"lead_process_status_name"}
                    valueField={"id"}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />
                  {this.state.login_user_role_id === 12 ? null : (
                    <Dropdown
                      value={this.state.filterSupplier}
                      key={"supplier_name"}
                      field={"filterSupplier"}
                      title={"Select Supplier"}
                      requestURL={"supplier/getActiveSuppliers"}
                      labelField={"supplier_name"}
                      valueField={"id"}
                      multiSelect
                      onChange={this.handleDropdownChange}
                    />
                  )}
                  {this.state.login_user_role_id === 13 ? (
                    <Dropdown
                      value={this.state.filterManager}
                      key={"manager_name"}
                      field={"filterManager"}
                      title={"Select Manager"}
                      requestURL={"users/getActiveManagers"}
                      labelField={"full_name"}
                      valueField={"id"}
                      multiSelect
                      onChange={this.handleDropdownChange}
                    />
                  ) : null}
                  <Dropdown
                    value={this.state.filterCampaign}
                    key={"campaign_name"}
                    field={"filterCampaign"}
                    title={"Campaign"}
                    requestURL={"campaign/getCampaignList"}
                    labelField={"campaign_slug"}
                    valueField={"campaign_slug"}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />
                  <Dropdown
                    value={this.state.filterStage}
                    key={"filterStage"}
                    field={"filterStage"}
                    title={"Select Stage"}
                    labelField={"lead_process_stage_name"}
                    valueField={"id"}
                    data={this.state.stagingList}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />
                  <Dropdown
                    value={this.state.filterAgent}
                    key={"filterAgent"}
                    field={"filterAgent"}
                    title={"Select Agent"}
                    labelField={"full_name"}
                    valueField={"id"}
                    data={this.state.agentList}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />
                  <Dropdown
                    value={this.state.filterProduct}
                    key={"filterProduct"}
                    field={"filterProduct"}
                    title={"Select Product"}
                    labelField={"product_name"}
                    valueField={"id"}
                    data={this.state.productList}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />

                  <div className="col-xl-12">
                    <div className="datepicker input-daterange row">
                      <div className="col-xl-12">
                        <div className="filter-item mb-2">
                          <label className="text-muted" for="FilterDateFrom">
                            From Date
                          </label>
                          <input
                            type="text"
                            id="startDate"
                            name="filterStartDate"
                            className="form-control text-left date-input"
                            value={this.state?.filterStartDate}
                            placeholder="DD-MM-YY"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="filter-item mb-2">
                          <label className="text-muted" for="FilterDateTo">
                            To Date
                          </label>
                          <input
                            type="text"
                            className="form-control text-left date-input"
                            id="endDate"
                            name="filterEndDate"
                            value={this.state?.filterEndDate}
                            placeholder="DD-MM-YY"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Dropdown
                    value={this.state.filterState}
                    key={"filterState"}
                    field={"filterState"}
                    title={"Select Province"}
                    labelField={"state_name"}
                    valueField={"id"}
                    data={this.state.staleList}
                    multiSelect
                    onChange={this.handleDropdownChange}
                  />
                </div>

                <div className="filter-search-btn text-center d-flex justify-content-center">
                  <button
                    type="reset"
                    onClick={this.handleClickReset}
                    className="btn btn-outline-primary mr-2"
                  >
                    RESET
                  </button>
                  <button className="btn btn-primary">SEARCH</button>
                </div>
                <button type="button" className="btn-search-close">
                  &times;
                </button>
              </div>
            </div>
            <div className="filter-backdrop"></div>
          </form>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          {this.state.flashMessage ? this.state.flashMessage : ""}
          <div className="card" style={{ overflow: "auto" }}>
            <header className="card-header card-table py-3 d-sm-flex align-items-center bg-white justify-content-between">
              <div className="recourd-number bold flex-grow-0">
                Total Records: {this.state.totalLeads}{" "}
              </div>

              <div className="d-flex align-items-center justify-content-between mt-sm-0 mt-3 flex-grow-1">
                <div className="ml-sm-2 d-flex align-items-center">
                  <label
                    className="text-muted m-0 pr-2 per-pg"
                    for="FilterCity"
                  >
                    {" "}
                    Per Page
                  </label>
                  <select
                    className="form-control selectpicker col-sm"
                    data-width="150"
                    name="perpage"
                    id="perpage"
                    value={this.state.perPage}
                    onChange={this.hendelPerPageChange}
                  >
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="50"> 50</option>
                    <option value="100"> 100</option>
                    <option value="150"> 150</option>
                    <option value="200"> 200</option>
                  </select>
                </div>
                <div className="d-flex">
                  {checkBtnPermission.getButton(
                    "add",
                    "/lead/add",
                    edit_icon,
                    this.props,
                    Link,
                    this
                  )}
                  {checkBtnPermission.getButton(
                    "download_csv",
                    "/lead/download_csv",
                    "",
                    this.props,
                    Link,
                    this
                  )}
                  {checkBtnPermission.getButton(
                    "upload_csv",
                    "/lead/upload_csv",
                    "",
                    this.props,
                    Link,
                    this,
                    this.state.uploading
                  )}
                </div>
              </div>
            </header>
            <div className="card-body p-0">
              <div
                className="table-responsive"
                style={{ height: window.innerHeight - 320 }}
              >
                <table className="table table-striped table-hover v-align-middle dashboard-table mb-0">
                  <thead className="bg-white">
                    <tr>
                      <th className="col-fixed">Action</th>
                      <th>Lead No.</th>
                      <th>Lead Stage</th>
                      <th>Lead Status</th>
                      <th className="col-sort">Product Name </th>
                      {/* <th className="col-sort">Sub Product </th> */}
                      <th
                        className="col-sort"
                        data-sortBy=""
                        data-sortName="full_name"
                      >
                        Customer Name <i className="fa fa-sort ml-2"></i>
                      </th>
                      <th
                        className="col-sort"
                        data-sortBy=""
                        data-sortName="email_id"
                      >
                        Customer Email <i className="fa fa-sort ml-2"></i>{" "}
                      </th>
                      <th className="col-sort">Mobile Number</th>
                      <th className="col-sort">City </th>
                      <th>Supplier Name</th>
                      <th>Campaign</th>
                      <th>Agent Name</th>
                      {/* <th>Agent Email</th>
                      <th>Agent Contact</th> */}
                      <th>Loan Amount</th>
                      <th width="50">Source</th>
                      <th>Comment</th>
                      <th>Client Remarks</th>
                      <th>Created</th>
                      <th>Updated</th>
                    </tr>
                  </thead>
                  <tbody
                    ref={(_) => {
                      this.tableBodyRef = _;
                    }}
                  >
                    {typeof this.state.leadList[0] === "object" ? (
                      this.state.leadList.map((lead, index) => {
                        let lead_value =
                          lead.lead_process_status != null
                            ? lead.lead_process_status.lead_process_status_name
                            : "";

                        return (
                          <tr
                            style={
                              lead_value === "Converted"
                                ? { backgroundColor: "#90ee90", height: 40 }
                                : { height: 40 }
                            }
                          >
                            <td className="nowrap col-fixed">
                              {checkBtnPermission.getButton(
                                "view",
                                "./lead/view/" + lead.id,
                                view_icon,
                                this.props,
                                Link,
                                this
                              )}
                              {checkBtnPermission.getButton(
                                "edit",
                                "./lead/edit/" + lead.id,
                                edit_icon,
                                this.props,
                                Link,
                                this
                              )}

                              {this.state.login_user_role_id === 1 &&
                              lead_value !== "Converted" ? (
                                <button
                                  className="btn-icon text-center"
                                  title="Assign Agent"
                                  key={lead.id}
                                  onClick={() => this.openModal(lead.id)}
                                >
                                  <img src={agent_icon} alt="" />
                                </button>
                              ) : (
                                ""
                              )}

                              {/* <a href="#" className="btn-icon text-center" title="Assign Agent"><img src={agent_icon} alt="" /></a> */}
                            </td>

                            <td>{parseInt(lead.id) + 1000}</td>
                            <td className="nowrap">
                              {lead.lead_process_stage != null
                                ? lead.lead_process_stage
                                    .lead_process_stage_name
                                : ""}
                            </td>
                            <td className="nowrap">
                              {lead.lead_process_status != null
                                ? lead.lead_process_status
                                    .lead_process_status_name
                                : ""}
                            </td>

                            <td className="nowrap">
                              {lead.pg != null ? lead.pg.product_name : ""}
                            </td>
                            {/* <td className="nowrap">
                              {lead.spg != null &&
                              lead.spg.product_name !== "No"
                                ? lead.spg.product_name
                                : ""}
                            </td> */}
                            <td className="nowrap">
                              {Appconfig.getName(lead)}
                            </td>

                            <td className="nowrap">
                              <i
                                className={
                                  lead.email_verify
                                    ? "fa fa-infos fa-check text-green"
                                    : "fa fa-infos fa-close text-red"
                                }
                              ></i>{" "}
                              {lead.email_id ? lead.email_id : ""}
                            </td>
                            <td className="nowrap">
                              <i
                                className={
                                  lead.mobile_verify
                                    ? "fa fa-infos fa-check text-green"
                                    : "fa fa-infos fa-close text-red"
                                }
                              ></i>{" "}
                              {lead.contact_number
                                ? common_helper.formatPhoneNumber(
                                    lead.contact_number
                                  )
                                : ""}
                            </td>
                            <td className="nowrap">
                              {lead.city ? lead.city.city_name : ""}
                            </td>
                            <td className="nowrap">
                              {lead.supplier != null
                                ? lead.supplier.supplier_name
                                : ""}
                            </td>
                            <td className="nowrap">
                              {lead.campaign != null
                                ? lead.campaign.campaign_name
                                : ""}
                            </td>
                            <td className="nowrap">
                              {lead.user != null ? lead.user.full_name : ""}
                            </td>
                            {/* <td className="nowrap">
                              {lead.user != null ? lead.user.email_id : ""}
                            </td>
                            <td className="nowrap">
                              {lead.user != null
                                ? common_helper.formatPhoneNumber(
                                    lead.user.contact_number
                                  )
                                : ""}
                            </td> */}
                            <td className="nowrap">
                              {parseInt(lead.loan_amount)
                                ? lead.loan_amount
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : ""}
                            </td>
                            <td className="nowrap">{lead.source_of_lead}</td>
                            <td className="nowrap">{lead.comment}</td>
                            <td className="nowrap">{lead.admin_comment}</td>
                            <td className="nowrap">
                              {common_helper.getDate(lead.createdAt)}{" "}
                            </td>
                            <td className="nowrap">
                              {common_helper.getDate(lead.updatedAt)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="10">Record Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <AgentAssignModal
              modalIsOpen={this.state.modalIsOpen}
              closeModal={this.closeModal}
              modalId={this.state.modalId}
            />
          </div>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination justify-content-center mt-3"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}

const Leads = (props) => {
  const { globalFilters, setGlobalFilters } = useContext(FilterContext);
  const { notify } = useToast();
  return (
    <LeadsList
      {...props}
      notify={notify}
      globalFilters={globalFilters}
      setGlobalFilters={setGlobalFilters}
    />
  );
};
export default Leads;
