import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import * as Appconfig from "../helpers/config";
//Redirect
import Login from "../pages/login";

//import Dashboard from '../pages/dashboard'
//import { Redirect} from 'react-router-dom';
import Accounts from "./accounts/account_list";
import AccountView from "./accounts/account_view";

import Users from "./users/user_list";
import UserAdd from "./users/user_add";
import UserEdit from "./users/user_edit";
import UserView from "./users/user_view";

import Document from "./documents/document_list";
import DocumentAdd from "./documents/add_document";
import DocumentEdit from "./documents/edit_document";

import Roles from "./user_roles/roles";
import RoleAdd from "./user_roles/role_add";
import RoleEdit from "./user_roles/role_edit";

import Leads from "./leads/lead_list";
import LeadAdd from "./leads/add_lead";
import LeadEdit from "./leads/edit_lead";
import LeadView from "./leads/lead_view";

import productList from "./products/productList";
//const productList = React.lazy(() => import('./products/productList'));  <React.Suspense fallback={<Spinner />}>
import productAdd from "./products/productAdd";
import productEdit from "./products/productEdit";

import Profile from "./profile";
import ApplyLeave from "./apply_leave";
import Setting from "./setting";
import Dashboard from "../pages/dashboard";
import Forgotpassword from "./Forgotpassword";
import Resetpassword from "./Resetpassword";
import Mailverification from "./mailverification";
import NotFound from "../pages/404page";

import States from "./states/state_list";
import StateAdd from "./states/add_state";
import StateEdit from "./states/edit_state";

import Cities from "./cities/city_list";
import CityAdd from "./cities/add_city";
import CityEdit from "./cities/edit_city";

import Zipcodes from "./zipcodes/zipcode_list";
import ZipcodeAdd from "./zipcodes/add_zipcode";
import ZipcodeEdit from "./zipcodes/edit_zipcode";

import LeadProcessStatus from "./lead_process_status/status_list";
import LeadProcessStatusAdd from "./lead_process_status/add_status";
import LeadProcessStatusEdit from "./lead_process_status/edit_status";

import LeadProcessStage from "./lead_process_stage/stage_list";
import LeadProcessStageAdd from "./lead_process_stage/add_stage";
import LeadProcessStageEdit from "./lead_process_stage/edit_stage";

import Campaigns from "./campaigns/campaign_list";
import CampaignAdd from "./campaigns/add_campaign";
import CampaignEdit from "./campaigns/edit_campaign";
import CampaignView from "./campaigns/campaign_view";

import ProfileEdit from "./profile_edit";
import SettingView from "./settings/setting_view";
import SettingEdit from "./settings/setting_edit";

import Lenders from "./lenders/lender_list";
import LenderAdd from "./lenders/add_lender";
import LenderEdit from "./lenders/edit_lender";

import Suppliers from "./suppliers/supplier_list";
import SupplierAdd from "./suppliers/add_supplier";

import MailTemplate from "./mailtempletes/template_list";
import MailTemplateAdd from "./mailtempletes/add_template";
import MailTemplateEdit from "./mailtempletes/edit_template";

import * as usersessionHelper from "../helpers/lms_usersession";
import DashboardUpdate from "../pages/dashboardUpdate";
import DashboardNew from "../pages/newDashboard";
import HierarchyDashboard from "../pages/hierarchyDashboard";
import leadDistrubutionReport from "./report/leadDistrubutionReport";

const Main = () => (
  <main>
    <Switch>
      <AuthenticatedRoute exact path="/roles" component={Roles} />
      <AuthenticatedRoute exact path="/role/add" component={RoleAdd} />
      <AuthenticatedRoute
        exact
        path="/role/edit/:roleId"
        component={RoleEdit}
      />

      <AuthenticatedRoute exact path="/users" component={Users} />
      <AuthenticatedRoute exact path="/user/add" component={UserAdd} />
      <AuthenticatedRoute exact path="/user/edit/:userId" component={UserAdd} />
      <AuthenticatedRoute
        exact
        path="/user/view/:userId"
        component={UserView}
      />

      <AuthenticatedRoute
        exact
        path="/mailtemplates"
        component={MailTemplate}
      />
      <AuthenticatedRoute
        exact
        path="/mailtemplate/add"
        component={MailTemplateAdd}
      />
      <AuthenticatedRoute
        exact
        path="/mailtemplate/edit/:id"
        component={MailTemplateEdit}
      />

      <AuthenticatedRoute exact path="/accounts" component={Accounts} />
      <AuthenticatedRoute
        exact
        path="/account/view/:id"
        component={AccountView}
      />

      <AuthenticatedRoute exact path="/leads" component={Leads} />
      <AuthenticatedRoute exact path="/lead/add" component={LeadAdd} />
      <AuthenticatedRoute
        exact
        path="/lead/edit/:leadId"
        component={LeadEdit}
      />
      <AuthenticatedRoute
        exact
        path="/lead/view/:leadId"
        component={LeadView}
      />

      <AuthenticatedRoute exact path="/products" component={productList} />

      <AuthenticatedRoute exact path="/product/add" component={productAdd} />
      <AuthenticatedRoute
        exact
        path="/product/edit/:id"
        component={productEdit}
      />

      <AuthenticatedRoute exact path="/states" component={States} />
      <AuthenticatedRoute exact path="/state/add" component={StateAdd} />
      <AuthenticatedRoute
        exact
        path="/state/edit/:stateId"
        component={StateEdit}
      />

      <AuthenticatedRoute exact path="/cities" component={Cities} />
      <AuthenticatedRoute exact path="/city/add" component={CityAdd} />
      <AuthenticatedRoute
        exact
        path="/city/edit/:cityId"
        component={CityEdit}
      />

      <AuthenticatedRoute exact path="/lenders" component={Lenders} />
      <AuthenticatedRoute exact path="/lender/add" component={LenderAdd} />
      <AuthenticatedRoute
        exact
        path="/lender/edit/:LenderId"
        component={LenderEdit}
      />
      <AuthenticatedRoute exact path="/suppliers" component={Suppliers} />
      <AuthenticatedRoute exact path="/supplier/add" component={SupplierAdd} />
      <AuthenticatedRoute
        exact
        path="/supplier/edit/:SupplierId"
        component={SupplierAdd}
      />

      <AuthenticatedRoute exact path="/zipcodes" component={Zipcodes} />
      <AuthenticatedRoute exact path="/zipcode/add" component={ZipcodeAdd} />
      <AuthenticatedRoute
        exact
        path="/zipcode/edit/:zipcode_id"
        component={ZipcodeEdit}
      />

      <AuthenticatedRoute
        exact
        path="/lead_process_status"
        component={LeadProcessStatus}
      />
      <AuthenticatedRoute
        exact
        path="/lead_process_status/add"
        component={LeadProcessStatusAdd}
      />
      <AuthenticatedRoute
        exact
        path="/lead_process_status/edit/:id"
        component={LeadProcessStatusEdit}
      />

      <AuthenticatedRoute
        exact
        path="/lead_process_stage"
        component={LeadProcessStage}
      />
      <AuthenticatedRoute
        exact
        path="/lead_process_stage/add"
        component={LeadProcessStageAdd}
      />
      <AuthenticatedRoute
        exact
        path="/lead_process_stage/edit/:id"
        component={LeadProcessStageEdit}
      />
      <AuthenticatedRoute exact path="/documents" component={Document} />
      <AuthenticatedRoute exact path="/document/add" component={DocumentAdd} />
      <AuthenticatedRoute
        exact
        path="/document/edit/:id"
        component={DocumentEdit}
      />
      <AuthenticatedRoute exact path="/campaigns" component={Campaigns} />
      <AuthenticatedRoute exact path="/campaign/add" component={CampaignAdd} />
      <AuthenticatedRoute
        exact
        path="/campaign/edit/:id"
        component={CampaignAdd}
      />
      <AuthenticatedRoute
        exact
        path="/campaign/view/:id"
        component={CampaignView}
      />

      <AuthenticatedRoute exact path="/settings" component={SettingView} />
      <AuthenticatedRoute exact path="/setting/edit" component={SettingEdit} />
      <AuthenticatedRoute exact path="/applyleave" component={ApplyLeave} />

      <AuthenticatedRoute exact path="/dashboard" component={DashboardNew} />
      <AuthenticatedRoute
        exact
        path="/dashboard/new"
        component={DashboardUpdate}
      />
      <AuthenticatedRoute
        exact
        path="/dashboard/hierarchy"
        component={HierarchyDashboard}
      />
      <AuthenticatedRoute exact path="/profile" component={Profile} />
      <AuthenticatedRoute path="/profile_edit" component={ProfileEdit} />
      <AuthenticatedRoute exact path="/setting" component={Setting} />
      <Route exact path="/forgot-password" component={Forgotpassword} />
      <AuthenticatedRoute
        exact
        path="/lead-distribution"
        component={leadDistrubutionReport}
      />
      <Route exact path="/reset-password/:id" component={Resetpassword} />
      <Route
        exact
        path="/mailverification/:token"
        component={Mailverification}
      />
      <Route exact path="/" component={Login} />
      <Route component={NotFound} />
    </Switch>
  </main>
);
export default Main;

const AuthenticatedRoute = ({ component: Component, ...properties }) =>
  usersessionHelper.isLoggedInUser() === true ? (
    <Route
      {...properties}
      render={(props) =>
        true ? <Component {...props} /> : <Redirect to={"/dashboard"} />
      }
    />
  ) : (
    <Redirect to={"/"} />
  );
