import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";
import Dropdown from "../dropdown";

export default class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: parseInt(props.match.params.userId),
      productList: [],
      AgentList: {},
      rolesList: {},
      countryList: {},
      stateList: {},
      cityList: {},
      filterCityList: {},
      addMoreFormValue: [{ queue: "", periority: "" }],
      fields: {},
      name: "",
      last_name: "",
      email_id: "",
      country_code: "",
      contact_number: "",
      pssword: "",
      confirm_password: "",
      role_id: 4,
      country_id: "1",
      state_id: "9",
      city_id: "90",
      gender: "Male",
      status: 1,
      dob: moment().format("DD-MM-YYYY"),
      state: "",
      errors: {
        name: "",
        last_name: "",
        email_id: "",
        country_code: "",
        contact_number: "",
        pssword: "",
        confirm_password: "",
        role_id: "",
        country_id: "",
        state_id: "",
        city_id: "",
        gender: "",
        dob: "",
        status: "",
      },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      is_loader: true,
      showProductInput: true,
      showAgentInput: false,
      redirectToList: false,
    };

    this.emailInput = React.createRef();
    this.nameInput = React.createRef();
    this.lastNameInput = React.createRef();
    this.phoneInput = React.createRef();
    this.roleInput = React.createRef();
    this.productInput = React.createRef();
    //this.routingProfileInput = React.createRef();
    this.agentInput = React.createRef();
    this.managerListInput = React.createRef();
    this.managerInput = React.createRef();
    this.passwordInput = React.createRef();
    this.brokerCodeInput = React.createRef();
    this.confirm_passwordInput = React.createRef();

    this.countryInput = React.createRef();
    this.stateInput = React.createRef();
    this.cityInput = React.createRef();
    this.genderInput = React.createRef();
    this.dobInput = React.createRef();
    this.statusInput = React.createRef();
    this.codeInput = React.createRef();

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleManagerChange = this.handleManagerChange.bind(this);

    this.handleProductChange = this.handleProductChange.bind(this);
    this.addFormFields = this.addFormFields.bind(this);
    this.removeFormFields = this.removeFormFields.bind(this);
    //this.handleRoutingProfileChange    = this.handleRoutingProfileChange.bind(this);

    this.handleAgentChange = this.handleAgentChange.bind(this);
    this.handleBrokerCodeChange = this.handleBrokerCodeChange.bind(this);

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirm_passwordChange =
      this.handleConfirm_passwordChange.bind(this);

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // get role ,country, state and city list
    //http://localhost:3001/user/getUser/0
    let self = this;

    var token = Appconfig.getSessionToken();
    if (token) {
      if (this.state.userId) {
        fetch(Appconfig.api_base_url + "user/update/" + this.state.userId, {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        })
          .then((res) => res.json())
          .then(
            async (result) => {
              console.log("result", result);
              if (result.status == 0) {
                this.setState(
                  { apiError: result.message, isLoaded: false },
                  () => {
                    console.log("aaaaaaaaaaaa");
                  }
                );
              } else if (result.status === 403) {
                this.setState(
                  { apiError: result.message, isLoaded: false },
                  () => {
                    console.log("aaaaaaaaaaaa");
                  }
                );
              } else if (result.status === 1) {
                console.log("countryList1", result.countries);
                console.log("countryList1", this.state.inputFocus);

                var filterCity = [];
                result.cities.map((city) => {
                  if (city.state_id == result.data.state_id) {
                    filterCity.push(city);
                  }
                });
                console.log("filterCity", filterCity);

                var showProductInput = false;
                var showAgentInput = false;
                if (parseInt(result.data.role_id) === 4) {
                  showProductInput = true;
                }
                if (parseInt(result.data.role_id) === 6) {
                  showAgentInput = true;
                }

                var dob = result.data.dob;
                if (dob.indexOf("-") > 0) {
                  dob = dob.split("-").reverse().join("-");
                }

                var addMoreFormValue = result.data.queue_and_periority;
                if (!addMoreFormValue) {
                  addMoreFormValue = [{ queue: "", periority: "" }];
                }

                console.log("addMoreFormValue", addMoreFormValue);
                this.setState(
                  {
                    name: result.data.first_name,
                    last_name: result.data.last_name,
                    email_id: result.data.email_id,
                    country_code: result.data.country_code,
                    contact_number: result.data.contact_number,
                    role_id: result.data.role_id,
                    country_id: result.data.country_id,
                    state_id: result.data.state_id,
                    city_id: result.data.city_id,
                    gender: result.data.gender,
                    dob: dob ? dob : "",
                    countryList: result.countries,
                    stateList: result.states,
                    cityList: result.cities,
                    manager_id: result.manager_id,
                    filterCityList: filterCity,
                    rolesList: result.roles,
                    productList: result.products,
                    AwsqueueList: result.AwsqueueList,
                    status: result.status,
                    broker_code: result.data.broker_code,
                    product_id: result.productIds,
                    routing_profile_id: result.data.routing_profile_id,
                    addMoreFormValue: addMoreFormValue,
                    AgentList: result.AgentList,
                    agent_id: result.agentIds,
                    showAgentInput: showAgentInput,
                    showProductInput: showProductInput,
                    supplier_id: result.data.supplier_id,
                    ManagerList: result.ManagerList,
                  },
                  () => {
                    console.log("countryList2", typeof this.state.countryList);

                    setTimeout(function () {
                      $(".selectpicker").selectpicker("refresh");
                    }, 500);
                  }
                );
              }
              setTimeout(function () {
                self.setState({ is_loader: false });
              }, 500);
            },
            (error) => {
              this.setState({
                isLoaded: false,
              });
            }
          );
      } else {
        fetch(Appconfig.api_base_url + "user/add", {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        })
          .then((res) => res.json())
          .then(
            async (result) => {
              console.log("result", result);

              if (result.status === 0) {
                this.setState(
                  { apiError: result.message, isLoaded: false },
                  () => {
                    console.log("aaaaaaaaaaaa");
                  }
                );
              } else if (result.status === 403) {
                this.setState(
                  { apiError: result.message, isLoaded: false },
                  () => {
                    console.log("aaaaaaaaaaaa");
                  }
                );
              } else if (result.status === 1) {
                console.log("countryList1", result.countries);
                console.log("countryList1", this.state.inputFocus);
                this.setState(
                  {
                    countryList: result.countries,
                    stateList: result.states,
                    country_code: result.country_code,
                    cityList: result.cities,
                    rolesList: result.roles,
                    ManagerList: result.ManagerList,
                    AgentList: result.AgentList,
                    AwsqueueList: result.AwsqueueList,
                    productList: result.products,
                  },
                  () => {
                    setTimeout(function () {
                      $(".selectpicker").selectpicker("refresh");
                    }, 400);
                    console.log("countryList2", typeof this.state.countryList);
                  }
                );
              }
              setTimeout(function () {
                self.setState({ is_loader: false });
              }, 300);
            },
            (error) => {
              this.setState({
                isLoaded: false,
              });
            }
          );
      }
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleStateChange(event) {
    var stateId = event.target.value;
    var filterCity = [];
    this.setState({ state_id: event.target.value });

    this.state.cityList.map((city) => {
      if (city.state_id == stateId) {
        filterCity.push(city);
      }
    });
    console.log("filterCity", filterCity);
    this.setState({ filterCityList: filterCity });
    setTimeout(function () {
      $("#city-id").selectpicker("refresh");
    }, 500);
  }

  handleNameChange(event) {
    var name = event.target.value;
    name = name.trim();
    name = name.charAt(0).toUpperCase() + name.slice(1);
    const password = name ? name + "@2023" : "";
    this.setState({ name: name, password, confirm_password: password });
  }
  handleLastNameChange(event) {
    var last_name = event.target.value;
    last_name = last_name.trim();
    last_name = last_name.charAt(0).toUpperCase() + last_name.slice(1);
    this.setState({ last_name: last_name });
  }

  handleCodeChange(event) {
    this.setState({ country_code: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email_id: event.target.value });
  }
  handlePhoneChange(event) {
    this.setState({ contact_number: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleBrokerCodeChange(event) {
    this.setState({ broker_code: event.target.value });
  }
  handleConfirm_passwordChange(event) {
    this.setState({ confirm_password: event.target.value });
  }
  handleCountryChange(event) {
    this.setState({ country_id: event.target.value });
  }
  //handleStateChange(event)            {    this.setState({state_id: event.target.value});}
  handleCityChange(event) {
    this.setState({ city_id: event.target.value });
  }
  handleGenderChange(event) {
    this.setState({ gender: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  //handleRoutingProfileChange(event)           {    this.setState({routing_profile_id: event.target.value});}

  handleProductChange(event) {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ product_id: value }, () => {
      console.log("product_id", this.state.product_id);
    });
  }

  handleAgentChange(event) {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ agent_id: value }, () => {
      console.log("agent_id", this.state.agent_id);
    });
  }
  handleManagerForSupervisorChange(event) {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ agent_id: value }, () => {
      console.log("agent_id", this.state.agent_id);
    });
  }

  handleManagerChange(value) {
    this.setState({ manager_id: value });
  }
  handleSupervisorChange(value) {
    this.setState({ supervisor_id: value });
  }

  handleDobChange(event) {
    this.setState({ dob: event.target.value });
  }

  handleRoleChange(event) {
    const isAgent = parseInt(event.target.value) === 4;
    const isManager = parseInt(event.target.value) === 6;
    const isSupervisor = parseInt(event.target.value) === 13;

    this.setState(
      {
        showProductInput: isAgent,
        showAgentInput: isManager,
        role_id: event.target.value,
      },
      () => {
        setTimeout(function () {
          if (isManager) {
            $("#a-id").selectpicker("refresh");
          } else if (isAgent) {
            $("#p-id").selectpicker("refresh");
          } else if (isSupervisor) {
            $("#m-id").selectpicker("refresh");
          }
        }, 500);
      }
    );
  }

  handleSupplierChange = (value) => {
    this.setState({ supplier_id: value });
  };

  // add more queue function
  addFormFields() {
    this.setState((prevState) => ({
      addMoreFormValue: [
        ...prevState.addMoreFormValue,
        { queue: "", periority: "" },
      ],
    }));
  }

  removeFormFields(e) {
    let index = e.target.value;
    let addMoreFormValueArr = this.state.addMoreFormValue;
    if (index !== -1) {
      addMoreFormValueArr.splice(index, 1);
      this.setState({ addMoreFormValue: addMoreFormValueArr });
    }
  }

  hendelPeriorityChange(index, e) {
    let addMoreFormValueArr = this.state.addMoreFormValue;
    addMoreFormValueArr[index][e.target.name] = e.target.value;
    this.setState({ addMoreFormValue: addMoreFormValueArr }, () => {
      console.log("addMoreFormValue", this.state.addMoreFormValue);
    });
  }

  hendelQueueChange(index, e) {
    console.log("addMoreFormValue", e.target.name);
    console.log("addMoreFormValue", e.target.value);
    let addMoreFormValueArr = this.state.addMoreFormValue;
    addMoreFormValueArr[index][e.target.name] = e.target.value;
    this.setState({ addMoreFormValue: addMoreFormValueArr }, () => {
      console.log("addMoreFormValue", this.state.addMoreFormValue);
    });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;
    //Form validation
    this.setState({ is_loader: true });
    if (!this.state.status) {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }
    if (!this.state.dob) {
      formIsValid = false;
      errors["dob"] = "The DOB field is required.";
      this.dobInput.current.focus();
    }
    if (!this.state.gender) {
      formIsValid = false;
      errors["gender"] = "The gender field is required.";
      this.genderInput.current.focus();
    }
    if (!this.state.city_id) {
      formIsValid = false;
      errors["city_id"] = "The city field is required.";
      this.cityInput.current.focus();
    }
    if (!this.state.state_id) {
      formIsValid = false;
      errors["state_id"] = "The Province field is required.";
      this.stateInput.current.focus();
    }
    if (!this.state.country_id) {
      formIsValid = false;
      errors["country_id"] = "The country field is required.";
      this.countryInput.current.focus();
    }
    if (!this.state.userId) {
      if (!this.state.confirm_password) {
        formIsValid = false;
        errors["confirm_password"] = "The confirm password field is required.";
        this.confirm_passwordInput.current.focus();
      }
      if (!this.state.password) {
        formIsValid = false;
        errors["password"] = "The password field is required.";
        this.passwordInput.current.focus();
      }
    }
    /*
	if(!this.state.routing_profile_id && parseInt(this.state.role_id)===4){
		formIsValid = false; errors["routing_profile_id"] = "The routing profile field is required.";
		  this.routingProfileInput.current.focus();
    }
    */
    if (!this.state.product_id && parseInt(this.state.role_id) === 4) {
      formIsValid = false;
      errors["product_id"] = "The product field is required.";
      this.productInput.current.focus();
    }

    if (!this.state.agent_id && parseInt(this.state.role_id) === 6) {
      formIsValid = false;
      errors["agent_id"] = "The Agent field is required.";
      this.agentInput.current.focus();
    }

    if (parseInt(this.state.role_id) === 12 && !this.state.supplier_id) {
      formIsValid = false;
      errors["supplier_id"] = "The supplier id field is required.";
      // this.roleInput.current.focus();
    }
    if (!this.state.role_id) {
      formIsValid = false;
      errors["role_id"] = "The role field is required.";
      this.roleInput.current.focus();
    }
    if (!this.state.contact_number) {
      formIsValid = false;
      errors["contact_number"] = "The contact number field required.";
      this.phoneInput.current.focus();
    } else {
      var mobile = this.state.contact_number;
      if (mobile.length == 10 && mobile > 1000000000) {
        errors["contact_number"] = "";
      } else {
        formIsValid = false;
        errors["contact_number"] = "Please enter valid 10 digit mobile.";
        this.phoneInput.current.focus();
      }
    }

    if (!this.state.email_id) {
      formIsValid = false;
      errors["email_id"] = "The email field required.";
      this.emailInput.current.focus();
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email_id)) {
        formIsValid = false;
        errors["email_id"] = "Please enter valid email address.";
      }
    }

    if (!this.state.last_name) {
      formIsValid = false;
      errors["last_name"] = "The last name field is required.";
      this.lastNameInput.current.focus();
    }
    if (!this.state.name) {
      formIsValid = false;
      errors["name"] = "The name field is required.";
      this.nameInput.current.focus();
    }

    this.setState({ errors: errors });

    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      let passwordData = {};
      let url = Appconfig.api_base_url + "user/update/" + this.state.userId;
      let flashMessage = "Record has been updated successfully";
      if (!this.state.userId) {
        url = Appconfig.api_base_url + "user/add";
        flashMessage = "Record has been added successfully";
        passwordData = {
          password: this.state.password,
          confirm_password: this.state.confirm_password,
        };
      }
      if (token) {
        var settings = {
          url,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            first_name: this.state.name,
            last_name: this.state.last_name,
            email_id: this.state.email_id,
            country_code: this.state.country_code,
            contact_number: this.state.contact_number,
            role_id: this.state.role_id,
            product_id: this.state.product_id,
            agent_id: this.state.agent_id,
            manager_id: this.state.manager_id,
            country_id: this.state.country_id,
            state_id: this.state.state_id,
            city_id: this.state.city_id,
            gender: this.state.gender,
            dob: this.state.dob,
            broker_code: this.state.broker_code,
            status: this.state.status,
            routing_profile_id: this.state.routing_profile_id,
            queue_and_periority: JSON.stringify(this.state.addMoreFormValue),
            supplier_id: this.state.supplier_id,
            ...passwordData,
            //"routing_profile_id":this.state.routing_profile_id
          },
        };
        let self = this;
        try {
          $.ajax(settings).done(
            function (response) {
              //localStorage.setItem("lmsuser", JSON.stringify(response.token));
              self.setState({ is_loader: false });

              console.log("response", response);
              if (response.status === 1) {
                Appconfig.setFlashMessage(flashMessage);
                self.setState({ redirectToList: true });
              } else if (response.status === 0) {
                var errorsObj = response.error;

                Object.keys(errorsObj).map(function (key) {
                  console.log("key", key);
                  if (errorsObj.hasOwnProperty(key)) {
                    console.log("key", key);
                    console.log("error 2", errorsObj[key]);
                    errors[key] = errorsObj[key].message;

                    self.setState({ errors }, () => {
                      console.log("state", self.state.errors);
                    });
                  }
                });
              } else if (response.status === 401) {
                // window.location.href=Appconfig.site_url+'/users';
              }
            },
            () => {
              self.setState({ is_loader: false });
            }
          );
        } catch (e) {
          self.setState({ is_loader: false });
        }
        // end ajax
      } else {
        //window.location.href=Appconfig.site_url;
        console.log("auth token has been expired");
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.nameInput.current.focus();
    $("#dob")
      .datepicker({
        autoclose: true,
        //startDate: "0d",
        endDate: new Date(),
        format: "dd-mm-yyyy",
        //maxViewMode: 2,
        todayHighlight: true,
      })
      .on("changeDate", (e) => {
        //  e here contains the extra attributes
        this.handleDobChange(e);
      });
  }
  render() {
    if (this.state.AwsqueueList) var AwsqueueList = this.state.AwsqueueList;
    let pageTitle = this.state.userId ? "Edit User" : "Add New User";
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/users"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/users" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">{pageTitle}</h1>
          </header>

          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            <header className="card-header py-3 bg-white">
              <h4 className="m-0 bold">Basic Details</h4>
            </header>
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 form-group">
                      <label for="">
                        First Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter your first name"
                        value={this.state.name}
                        onChange={this.handleNameChange}
                        ref={this.nameInput}
                      />
                      <span className="error">{this.state.errors["name"]}</span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Last Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        placeholder="Enter your last name"
                        value={this.state.last_name}
                        onChange={this.handleLastNameChange}
                        ref={this.lastNameInput}
                      />
                      <span className="error">
                        {this.state.errors["last_name"]}
                      </span>
                    </div>
                    <div className="col-md-3 form-group">
                      <label for="">
                        Email Id<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email_id"
                        placeholder="Enter your email id"
                        value={this.state.email_id}
                        onChange={this.handleEmailChange}
                        ref={this.emailInput}
                      />
                      <span className="error">
                        {this.state.errors["email_id"]}
                      </span>
                    </div>
                    <div className="col-md-1 form-group">
                      <label for="">
                        Code <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="country_code"
                        maxlength="10"
                        placeholder="Enter Country code"
                        value={this.state.country_code}
                        onChange={this.handleCodeChange}
                        ref={this.codeInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["country_code"]}
                      </span>
                    </div>
                    <div className="col-md-2 form-group">
                      <label for="">
                        Mobile Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact_number"
                        maxlength="10"
                        placeholder="Enter your Contact Number"
                        value={this.state.contact_number}
                        onChange={this.handlePhoneChange}
                        ref={this.phoneInput}
                      />
                      <span className="error">
                        {this.state.errors["contact_number"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Roles<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        data-size="7"
                        value={this.state.role_id}
                        onChange={this.handleRoleChange}
                        ref={this.roleInput}
                      >
                        <option value="">Select Role</option>
                        {typeof this.state.rolesList[0] === "object"
                          ? this.state.rolesList.map((role) => {
                              return (
                                <option value={role.id}>
                                  {role.role_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["role_id"]}
                      </span>
                    </div>
                    {this.state.role_id == 12 ? (
                      <>
                        <Dropdown
                          className="col-md-3"
                          key={"supplier_name"}
                          field={"supplier_id"}
                          title={"Select Supplier"}
                          requestURL={"supplier/getActiveSuppliers"}
                          labelField={"supplier_name"}
                          valueField={"id"}
                          value={this.state.supplier_id}
                          onChange={this.handleSupplierChange}
                        />
                        <span className="error">
                          {this.state.errors["supplier_id"]}
                        </span>
                      </>
                    ) : null}

                    {this.state.showProductInput === true ? (
                      <div className="col-md-3 form-group">
                        <label for="">
                          Select Products<span className="text-red">*</span>
                        </label>
                        <select
                          className="form-control selectpicker"
                          title="Select Product"
                          id="p-id"
                          data-size="7"
                          multiple
                          value={this.state.product_id}
                          onChange={this.handleProductChange}
                          ref={this.productInput}
                        >
                          {typeof this.state.productList[0] === "object"
                            ? this.state.productList.map((product) => {
                                return (
                                  <option value={product.id}>
                                    {product.product_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        <span className="error">
                          {this.state.errors["product_id"]}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.showAgentInput === true ? (
                      <div className="col-md-3 form-group">
                        <label for="">
                          Select Agents<span className="text-red">*</span>
                        </label>
                        <select
                          className="form-control selectpicker"
                          title="Select Agents"
                          id="a-id"
                          data-size="7"
                          multiple
                          value={this.state.agent_id}
                          onChange={this.handleAgentChange}
                          ref={this.agentInput}
                        >
                          {typeof this.state.AgentList[0] === "object"
                            ? this.state.AgentList.map((agent) => {
                                return (
                                  <option value={agent.id}>
                                    {agent.full_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        <span className="error">
                          {this.state.errors["agent_id"]}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role_id == 4 ? (
                      <Dropdown
                        className="col-md-3"
                        requestURL={"user/getActiveManagerList"}
                        field={"manager_id"}
                        key={"manager_id"}
                        onChange={this.handleManagerChange}
                        labelField={"full_name"}
                        valueField={"id"}
                        title={"Select Manager"}
                        value={this.state.manager_id}
                      />
                    ) : (
                      ""
                    )}
                    {/* {this.state.role_id == 13 ? (
                      <div className="col-md-3 form-group">
                        <label for="">
                          Select Managers<span className="text-red">*</span>
                        </label>
                        <select
                          className="form-control selectpicker"
                          title="Select Managers"
                          id="m-id"
                          data-size="7"
                          multiple
                          value={this.state.manager_id}
                          onChange={this.handleManagerForSupervisorChange}
                          ref={this.managerListInput}
                        >
                          {typeof this.state.ManagerList[0] === "object"
                            ? this.state.ManagerList.map((manager) => {
                                return (
                                  <option value={manager.id}>
                                    {manager.full_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        <span className="error">
                          {this.state.errors["manager_id"]}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role_id == 6 ? (
                      <Dropdown
                        className="col-md-3"
                        requestURL={"user/getActiveManagerListSupervisorWise"}
                        field={"supervisor_id"}
                        key={"supervisor_id"}
                        onChange={this.handleSupervisorChange}
                        labelField={"full_name"}
                        valueField={"id"}
                        title={"Select Supervisor"}
                        value={this.state.supervisor_id}
                      />
                    ) : (
                      ""
                    )} */}
                    <div className="col-md-3 form-group">
                      <label for="">Broker Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter broker code"
                        value={this.state.broker_code}
                        onChange={this.handleBrokerCodeChange}
                        ref={this.brokerCodeInput}
                      />
                      <span className="error">
                        {this.state.errors["broker_code"]}
                      </span>
                    </div>

                    {this.state.userId ? null : (
                      <>
                        <div className="col-md-3 form-group">
                          <label for="">
                            Password<span className="text-red">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your password"
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                            ref={this.passwordInput}
                          />
                          <span className="error">
                            {this.state.errors["password"]}
                          </span>
                        </div>
                        <div className="col-md-3 form-group">
                          <label for="">
                            Confirm Password <span className="text-red">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your confirm password"
                            value={this.state.confirm_password}
                            onChange={this.handleConfirm_passwordChange}
                            ref={this.confirm_passwordInput}
                          />
                          <span className="error">
                            {this.state.errors["confirm_password"]}
                          </span>
                        </div>
                      </>
                    )}

                    <div className="col-md-3 form-group">
                      <label for="">
                        Country<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        data-size="7"
                        value={this.state.country_id}
                        onChange={this.handleCountryChange}
                        ref={this.countryInput}
                      >
                        <option value="">Select Country</option>
                        {typeof this.state.countryList[0] === "object"
                          ? this.state.countryList.map((country) => {
                              return (
                                <option value={country.id}>
                                  {country.country_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["country_id"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Province<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        data-size="7"
                        value={this.state.state_id}
                        onChange={this.handleStateChange}
                        ref={this.stateInput}
                      >
                        <option value="">Select Province</option>
                        {typeof this.state.stateList[0] === "object"
                          ? this.state.stateList.map((state) => {
                              return (
                                <option value={state.id}>
                                  {state.state_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["state_id"]}
                      </span>
                    </div>
                    <div className="col-md-3 form-group">
                      <label for="">
                        City<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="city-id"
                        data-size="7"
                        value={this.state.city_id}
                        onChange={this.handleCityChange}
                        ref={this.cityInput}
                      >
                        <option value="">Select City</option>
                        {typeof this.state.filterCityList[0] === "object"
                          ? this.state.filterCityList.map((city) => {
                              return (
                                <option value={city.id}>
                                  {city.city_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["city_id"]}
                      </span>
                    </div>
                    <div className="col-md-3 form-group">
                      <label for="">
                        Gender<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.gender}
                        onChange={this.handleGenderChange}
                        ref={this.genderInput}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <span className="error">
                        {this.state.errors["gender"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Date Of Birth<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        name="dob"
                        id="dob"
                        className="form-control"
                        placeholder="Select DOB"
                        value={this.state.dob}
                        onChange={this.handleDobChange}
                        ref={this.dobInput}
                        autoComplete="off"
                      />
                      <span className="error">{this.state.errors["dob"]}</span>
                    </div>
                    <div className="col-md-3 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="pt-4 text-center">
                    <Link
                      to="/users"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
